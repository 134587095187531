// NEWS

.th-image img {
    width: 100%;
}

figcaption {
    margin-top: .5rem;
    font-style: italic;
    color: $dark-gray;
}

article {
    margin-bottom: 4rem;
}