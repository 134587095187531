header {

    @include breakpoint(large) {
        padding: 1rem 0;
    }
}

.logo img {
    width: 60vw;
    max-width: 300px;
    height: auto;
    margin: 2rem 0;
}

.logo-desktop {
    display: none;

    a {
        width: 20vw;
        max-width: 250px;
    }

    @include breakpoint(large) {
        display: block;
        
        img {
            color: #fff;
            width: 20vw;
            max-width: 250px;
        }
    }

}

.mobile-menu {
    padding: 1rem;
}

.mobile-menu-button {
    color: $qc-green;
    font-size: 2rem;
}

.main-menu {
    background: $qc-green; 
    flex-direction: column;
    padding: 2rem 0;

    a {
        color: rgba(255,255,255,.9);
        font-weight: 700;
        text-transform: uppercase;
        font-size: .85rem;
        padding: 1rem 2rem;
    }

    @include breakpoint(large) {
        background: white;
        flex-direction: row;
        padding: 0 1rem;

        a {
            color: $qc-green;
            font-weight: 700;
            text-transform: uppercase;
        }
    }


}

#mainMenu {
    display:none;

    @include breakpoint(large) {
        display: flex;
    }
}

#mainMenu.expanded {
    display:flex;
}