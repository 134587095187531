footer {
    background: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8));
    background-size: cover;
    color: white;
    min-height: 300px;
    text-align: center;
    display: flex;
    align-content: center;


    a {
        color: $white;
        font-weight: bold;
    }

    a:hover {
        color: $light-gray;
    }

    .footer-content {
        min-height: 300px;
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;
    }

    @include breakpoint(large) {
    padding-top: 4rem;
    }

    .phone {
        font-size: 2rem;
    }

    .menu {
        margin-top: 3rem;

        @include breakpoint(large) {
            margin-top: 1rem;
        }
    }

    .menu a {
        color: white;
    }
}

.social {
    padding-top: 1rem;
}

.social a {
    font-size: 2rem;
    display: inline;
}

.copyright {
    text-align: center;
}