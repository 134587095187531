.hero {
    max-height: 85vh;
    display: flex;
    position: relative;
    
    img {
        object-fit: cover;
        object-position: center;
        min-width: 100%;
    }

    .text-block {
        border-top: 1px solid $qc-green;
        position: absolute;
        bottom: 20%;
        right: 0;
        background-color: rgba(0,0,0,.6);
        color: white;
        padding: 1rem 2rem;
        text-align: right;
    }
}

.home .hero img {
    object-position: top;
}

.summaries-reports .hero img {
    object-position: top;
}