.options {
    padding: 4rem 0;
    background: rgba(0,0,0,.12);
    background-size: cover;

    .card {
        border-top: 1px solid $qc-green;
        text-align: center;
    }

    .card-section {
        border-top: 1px solid $qc-green;
    }

}