@charset 'utf-8';

@import 'settings';
@import 'node_modules/foundation-sites/assets/foundation';
@import 'node_modules/motion-ui/motion-ui';



// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;


// Components
@import 'components/bio_index';
@import 'components/bio_single';
@import 'components/footer';
@import 'components/header';
@import 'components/hero';
@import 'components/main_content';
@import 'components/news';
@import 'components/options';
@import 'components/typography';
@import 'components/contact';


main {
    min-height: 40vh;
}

.intro {
    padding: 4rem 0;

    a {
        text-decoration: underline;
    }
}

h1, h2, h3 {
    letter-spacing: -.5px;
}

.wrap {
    background-color: $light-gray;
    padding-top: .5rem;
    padding-bottom: .5rem;
}