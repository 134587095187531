// TYPOGRAPHY

h2 {
    margin-top: 2.8rem;
}

h3 {
    margin-top: 2.1rem;
    margin-bottom: .35rem;
}

p {
    margin-bottom: 1.4rem;
}