// BIO INDEX

.bio {
    text-align: center;

    img {
        border-radius: 50%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        border: 1px solid $qc-green;
    }

    .button {
        margin-top: 1rem;
    }
}